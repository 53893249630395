import React from "react";


export default function IndexComponent() {

    return (
        <div id="preloode-wrapper">
            <section className="pp-scrollable welcome-section">
                <div id="particles"></div>
                <div className="section">
                    <p><i className="ti ti-comet"></i>Welcome</p>
                    <h2>Preloode Digital Teknologi</h2>
                    <div className="welcome-button">
                        <button>See our product</button>
                        <button>Start a project</button>
                    </div>
                </div>
            </section>
            <section className="pp-scrollable about-section">
                <div className="section-title">
                    <p><i className="ti ti-user-scan"></i>About Us</p>
                </div>
                <div className="section grid-2-section">
                    <div className="grid-2-content">
                        <h2><i className="ti ti-user-scan"></i>About Us</h2>
                        <p>Preloode Digital Teknologi adalah IT tech company yang dibentuk pada tahun 2022.
                            Didirikan oleh seorang founder yang bernama Rizky Pratama yang sebelumnya bekerja di IT tech
                            company kurang lebih 10 tahun sebelum memutuskan untuk mendirikan IT tech company nya
                            sendiri.</p>
                        <p>Preloode Digital Teknologi menyediakan beberapa service di bidang IT tech, seperti software
                            development, IOT development, server installation, dan network installation.</p>
                        <p>Selain service Preloode Digital Teknologi juga menjual akun VPN dengan IP Indonesia yang
                            dapat digunakan untuk menjaga privasi anda pada saat mengakses internet, dan domain
                            checker yang dapat digunakan untuk memberikan peringatan ketika domain atau server anda
                            sedang down.</p>
                    </div>
                    <div className="grid-2-content">
                        <img src="/images/avatar/rizky-pratama.jpg" alt={process.env.REACT_APP_COMPANY_NAME + ' Founder'}/>
                    </div>
                </div>
            </section>
            <section className="pp-scrollable service-section">
                <div className="section-title">
                    <p><i className="ti ti-code"></i>Our Service</p>
                </div>
                <div className="section grid-2-section">
                    <div className="grid-2-content">
                        <h2><i className="ti ti-code"></i>Our Service</h2>
                        <p>Teknologi yang kami gunakan dalam software development dan IOT development adalah Python,
                            Java, C++, C#, PHP, MongoDB, MySQL, SQL Server, HTML5, CSS3, dan Javascript.</p>
                        <p>Dalam server installation kami menggunakan beberapa operating system berbasis Linux seperti
                            Ubuntu, CentOS, dan Debian</p>
                        <p>Kami juga memegang sertifikasi dari bidang networking yang diberikan oleh Mikrotik dengan
                            level MTCRE. sertifikasi ini merupakan sertifikasi dengan level intermediate yang diberikan
                            oleh Mikrotik</p>
                        <button>Start a project</button>
                    </div>
                    <div className="grid-2-content icon-content">
                        <div className="icon-item">
                            <i className="ti ti-code"></i>
                            <p>Software Development</p>
                        </div>
                        <div className="icon-item">
                            <i className="ti ti-robot"></i>
                            <p>IOT Development</p>
                        </div>
                        <div className="icon-item">
                            <i className="ti ti-cpu"></i>
                            <p>Server Installation</p>
                        </div>
                        <div className="icon-item">
                            <i className="ti ti-network"></i>
                            <p>Network Installation</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pp-scrollable product-section">
                <div className="section-title">
                    <p><i className="ti ti-server"></i>Our Product</p>
                </div>
                <div className="section grid-2-section">
                    <div className="grid-2-content">
                        <h2><i className="ti ti-server"></i>Our Product</h2>
                        <p>Kami menyediakan produk akun VPN dengan IP Indonesia yang dapat digunakan untuk melindungi
                            privasi anda pada saat mengakses internet.</p>
                        <p>Kami juga menyediakan domain checker yang dapat digunakan untuk memberikan peringatan ketika
                            domain atau server anda sedang down.</p>
                        <button>Buy product</button>
                    </div>
                    <div className="grid-2-content icon-content">
                        <div className="icon-item">
                            <i className="ti ti-brand-openvpn"></i>
                            <p>VPN Account</p>
                        </div>
                        <div className="icon-item">
                            <i className="ti ti-world-minus"></i>
                            <p>Domain Checker</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}
