import {Link, Outlet, useLocation} from "react-router-dom";


export default function LayoutComponent() {

    const location: any = useLocation();

    return (
        <div id="wrapper">
            <div id="preloader">
                <div className="cube">
                    <div className="load-cube c1"></div>
                    <div className="load-cube c2"></div>
                    <div className="load-cube c4"></div>
                    <div className="load-cube c3"></div>
                </div>
            </div>
            <div id="header">
                <div className="logo">
                    <h1>
                        <Link to={'/'}>
                            <img src="/images/logo/logo.png" alt={process.env.REACT_APP_COMPANY_NAME}/>
                        </Link>
                    </h1>
                </div>
                <div className="contact">
                    <div className="contact-item">
                        <Link to={'https://wa.me/6285772723989/'} target="_blank">
                            <i className="ti ti-brand-whatsapp"></i>
                        </Link>
                    </div>
                    <div className="contact-item">
                        <Link to={'https://www.instagram.com/rizkypratama.tan/'} target="_blank">
                            <i className="ti ti-brand-instagram"></i>
                        </Link>
                    </div>
                    <div className="contact-item">
                        <Link to={'https://www.facebook.com/rizkypratama.tan/'} target="_blank">
                            <i className="ti ti-brand-facebook"></i>
                        </Link>
                    </div>
                </div>
                <div className="account">
                    {/*<div className="user-name">
                        <i className="ti ti-user-check"></i>Rizky Pratama
                    </div>
                    <div className="cart">
                        <i className="ti ti-shopping-cart"></i>
                    </div>*/}
                </div>
                <div className="menu">
                    <i className="ti ti-align-right"></i>
                </div>
            </div>
            <Outlet></Outlet>
        </div>
    );

}
